
body {
    margin: 0px;
    padding: 0px; 
}
.admission-drawer {
    .form-group {
        & > label {
            padding: 0;
            padding-left: 15px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 0.4rem;
    
            @media screen and (max-width: 575px) {
                justify-content: flex-start;
                padding-left: 15px;
            }
        } 

        .col-sm-8 {
            .col-sm-8.col-form-label {
                top: 3px;
                padding-top: 0.7rem;
                padding-bottom: 0.7rem;
            }
        }
    }

    &.drawer {
        @media screen and (max-width: 1350px) {
            width: 60% !important;
        }
        @media screen and (max-width: 1150px) {
            width: 70% !important;
        }
        @media screen and (max-width: 566px) {
            width: 95% !important;
        }
    }
}

.quick-admission-btn {
    margin: 0 0 0 auto;
    padding-right: 15px;
}

.drawer {
    .quick-admission-drawer-btn {
        margin-top: -2rem;
        margin-bottom: 2rem;
    }
}

.dropdown-menu > a {
    white-space: nowrap !important;
}

.sub-title {
    border-radius: 0.4rem;
    border: 1px solid rgba(0,0,0,0.7);
    margin-bottom: 0;
    margin-top: 0.3rem;

    .left {
        padding-right: 0.4rem;
        padding-left: 0.4rem;
        display: inline-block;
    }

    .right {
        border-bottom-right-radius: 0.4rem;
        border-top-right-radius: 0.4rem;
        background-color: black;
        color: #fff;
        padding-right: 0.4rem;
        padding-left: 0.4rem;
        display: inline-block;
    }
}

.auto-complete-row {
    margin-top: 0.4rem;
    margin-bottom: 1rem;

    .col-sm-4 {
        padding: 0;
        padding-left: 15px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 0.4rem;

        p {
            margin-bottom: 0;
        }
    }

    .col-sm-4,
    .col-sm-8 {
        margin-top: 0.4rem;
    }
}
